import React from "react";

enum Indices {
  Layer0,
  Layer1,
  Layer2,
  Layer3,
  Layer4,
  Layer5,
  Layer6,
  Layer7,
  Layer8,
  Layer9,
  Layer10,
  Layer11,
  Layer12,
  LayerMax = 99999,
}

// the inside canvas layers and outside layers will not interfere with each other
// TODO: split them into two Layer objects
export const Layers = {
  //-------------- inside canvas --------------

  dragPreview: Indices.Layer1,
  // All Widgets Parent layer (styles on PositionedContainer)
  positionedWidget: Indices.Layer1,
  focusedWidget: Indices.Layer3, //Also used in DropdownSearchable
  selectedWidget: Indices.Layer2,

  drawer: Indices.Layer6, // slideout
  closeButton: Indices.Layer4, // modal or slideout close button
  stickySections: Indices.Layer3,
  scrollbars: Indices.Layer5, // for ScrollContainer, inside positionedWidget
  componentBorder: Indices.Layer3, // for containers
  resizeWrapper: Indices.Layer4, // inside positioned widget, always show
  // only show when focused/selected, in parallel with widgetName
  selectedWrapper: Indices.Layer4,
  parentSelectedWrapper: Indices.Layer3,
  widgetResizer: Indices.Layer4, // widget resizer which is 4 edges and 4 corners
  // TODO: refactor the resizer below, looks like some is not needed.
  resizer: Indices.Layer6, // section column resizer and resizer disabled tooltip,
  modal: Indices.Layer7, // for modal component

  widgetName: Indices.Layer4,
  selectionConstraintResize: Indices.Layer4, // min max height constraint
  addSectionButton: Indices.Layer5,
  canvasBreadCrumbPopover: Indices.Layer5,
  // layers for vstack dnd
  vstackDropPlaceholder: Indices.Layer2,

  //-------------- outside canvas ----------------
  panelResizer: Indices.Layer6,
  actionCard: Indices.Layer2,
  apiPane: Indices.Layer3,
  propertiesPane: Indices.Layer5,
  propertiesPopupPanels: Indices.Layer8,
  explorerSidebar: Indices.Layer7,
  dynamicAutoComplete: Indices.LayerMax,
  contextMenuOverlay: Indices.Layer11,
  contextMenuContent: Indices.Layer12,
  addStepPopover: Indices.Layer9,
  dropDownHover: Indices.Layer10,
  navDividers: Indices.Layer1,
  entityHeaders: Indices.Layer3,
  entityContent: Indices.Layer2,
  sharedSidebarHeader: Indices.Layer2,
  intercom: Indices.LayerMax,
  scrollPreventer: Indices.Layer10,

  max: Indices.LayerMax,
};

export const LayersContext = React.createContext(Layers);
