import { Agent, UIErrorType } from "@superblocksteam/shared";
import { get } from "lodash";
import { call, put, select } from "redux-saga/effects";
import {
  StdISocketRPCClient,
  connectToISocketRPCServer,
} from "legacy/api/ISocketRPC";
import { ERROR_CODES } from "legacy/constants/ApiConstants";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import {
  getWorkflowProfiles,
  ProfilesWithModes,
} from "legacy/selectors/applicationSelectors";
import { getIsOrgSwitched } from "legacy/selectors/usersSelectors";
import { getOpaAgents } from "legacy/utils/getOpaAgents";
import { resetCachedCurrentBranch } from "pages/Repositories/utils";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";
import { orgIsOnPremise } from "store/slices/organizations/utils";
import { HttpError, ROOT } from "store/utils/types";

import logger, { stringifyError } from "utils/logger";
import { findAndUnMarshalProtoBlocks } from "utils/marshalProto";
import { sendErrorUINotification } from "utils/notification";
import {
  getShouldSignAndVerify,
  verifyResources,
} from "utils/resource-signing";
import { createSaga, SagaType } from "../../../utils/saga";

import { getApiV3 } from "../../apis/client";
import { ApiDto } from "../../apis/types";
import { Api } from "../backend-types";
import slice, { type ApiDtoWithPb } from "../slice";
import { getV2ApiId } from "../utils/getApiIdAndName";
interface FetchV2ApiPayload {
  apiId: string;
  environment: string;
  branch?: string;
  canCrash?: boolean;
}

export function* fetchV2ApiInternal({
  apiId,
  environment,
  branch,
  canCrash,
}: FetchV2ApiPayload) {
  // This is called in workflow/job only to fetch workflow/job
  // get profile given mode
  const enableProfiles: boolean = yield select(
    selectFlagById,
    Flag.ENABLE_PROFILES,
  );
  const profiles: ProfilesWithModes = yield select(getWorkflowProfiles, apiId);
  const profile = profiles?.editor.default;
  const organization: ReturnType<typeof selectOnlyOrganization> = yield select(
    selectOnlyOrganization,
  );
  const agents: Agent[] = yield call(getOpaAgents);

  let result: ApiDto;
  let shouldVerifySignature = false;
  let rpcClient: undefined | StdISocketRPCClient;
  try {
    if (orgIsOnPremise(organization)) {
      const isSigningEnabled: boolean = yield call(
        getShouldSignAndVerify,
        agents,
      );
      if (isSigningEnabled) {
        if (agents.length === 0) {
          throw new Error("Could not create branch, no OPA agents found");
        }
        rpcClient = yield call(connectToISocketRPCServer, agents, organization);
        shouldVerifySignature = true;
      }
    }

    if (rpcClient) {
      const response: Awaited<ReturnType<typeof rpcClient.call.v3.api.get>> =
        yield call(rpcClient.call.v3.api.get, { apiId, branchName: branch });
      if (response.responseMeta.status !== 200) {
        const status = response.responseMeta.status;
        throw new HttpError(
          status,
          !status || status >= 500,
          response.responseMeta.message,
        );
      }
      result = response.data as ApiDto;
    } else {
      result = yield call(getApiV3, {
        apiId,
        ...(enableProfiles ? { profile } : { environment }),
        ...(branch ? { branch } : {}),
        errorHandlingOptions: {
          notifyOnError: false,
          onError: (error) => {
            throw error;
          },
        },
      });
    }
  } catch (error: any) {
    resetCachedCurrentBranch(apiId);
    const code = get(error, "code", ERROR_CODES.SERVER_ERROR);
    if (canCrash) {
      yield put({
        type: ReduxActionErrorTypes.FETCH_WORKFLOW_OR_JOB_ERROR,
        payload: {
          error,
        },
      });
      logger.error(
        `Fetch api failed:\n  code: ${code}, error:\n  ${stringifyError(
          error,
        )}\n`,
        {
          superblocks_ui_error_type:
            code !== 404
              ? UIErrorType.CRASH_APP_ERROR
              : UIErrorType.SERVER_ERROR_4XX,
          superblocks_ui_error_code: code as number | undefined,
        },
      );

      // crash worflow page with error page if can't fetch workflow
      yield put({
        type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS_REQUEST,
        payload: {
          code,
        },
      });
    }
    throw error;
  } finally {
    rpcClient?.close();
  }

  if (shouldVerifySignature) {
    const isOrgSwitched: boolean = yield select(getIsOrgSwitched);
    if (!isOrgSwitched) {
      try {
        yield call(verifyResources, {
          resources: [{ api: result.apiPb as Api }],
          agents,
          organization,
          branchName: branch,
        });
      } catch (e: any) {
        if (canCrash) {
          yield put({
            type: ReduxActionErrorTypes.FETCH_WORKFLOW_OR_JOB_ERROR,
            payload: {
              error: e,
            },
          });
          // crash worflow page with error page if can't fetch workflow
          yield put({
            type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS_REQUEST,
            payload: {
              code: ERROR_CODES.VERIFY_RESOURCES_FAILED,
            },
          });
        } else {
          sendErrorUINotification({
            message: "Failed to verify resource",
          });
        }
        throw new Error("Could not verify resource");
      }
    }
  }
  yield put({
    type: ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
    payload: result.updated,
  });

  return result as ApiDtoWithPb;
}

export const fetchV2ApiSaga = createSaga(fetchV2ApiInternal, "fetchV2ApiSaga", {
  sliceName: "apis",
  type: SagaType.Throttled,
  delay: 1500,
});

slice.saga(fetchV2ApiSaga, {
  start(state) {
    state.loading[ROOT] = true;
  },
  success(state, { payload }) {
    if (!payload) return;

    findAndUnMarshalProtoBlocks(payload.apiPb);

    const apiId = getV2ApiId(payload);
    state.entities = { [apiId]: payload };
    delete state.loading[ROOT];
    state.meta[apiId] = state.meta[apiId] ?? {};
    state.meta[apiId].needsBindingExtraction = true;
  },
  error(state, { payload }) {
    state.errors[ROOT] = { error: payload };
    delete state.loading[ROOT];
  },
});
